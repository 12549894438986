import React, { Component } from 'react';
import { connect } from "react-redux";
import { initApp } from "../../containers/App/actionsAsync";
import { getCookieByName, setCookie } from "../../helpers/cookieUtils";
import Loader from "../Loader";

class AuthPage extends Component {

    state = {
        isShowLoadingTitle: true,
    };

    redirectTo = (path) => {
        const { history } = this.props;
        if (history.location.pathname !== path) {
            history.replace(path);
        }
    }

    componentDidMount() {
        const { location: { hash } } = this.props;
        const hashToken = this.getTokenParameter(hash);
        const authToken = getCookieByName('auth');

        if (authToken) {
            this.redirectTo('/');
        } else if (hashToken) {
            this.googleLogin(hashToken);
        } else {
            this.redirectTo('/login');
        }
    }

    getTokenParameter = hash => {
        //need to add this logic const cleanHash = hash.startsWith('#') ? hash.slice(1) : hash;
        const urlParams = new URLSearchParams(hash);
        return urlParams.get("id_token");
    }

    googleLogin = (hashToken) => {
        if (window.gapi) {
            window.gapi.load('auth2', () => {
                window.gapi.auth2.init({
                    client_id: '770875976056-qm72cts2365jjjsb3q99jcu4fnuro359.apps.googleusercontent.com',
                    scope: 'openid profile',
                }).then(() => {
                    const user = window.gapi.auth2.getAuthInstance().currentUser.get();
                    const token = user.getAuthResponse().id_token;
                    this.responseGoogleSuccess(token, hashToken);
                }).catch((err) => {
                    if (err.details === "Cookies are not enabled in current environment.") {
                        this.responseGoogleSuccess("", hashToken);
                    }
                });
            });
        } else {
            setTimeout(() => {
                this.googleLogin(hashToken);
            }, 10)
        }
    }

    responseGoogleSuccess = (tokenId, hashToken) => {
        const { initApp, history } = this.props;
        const token = tokenId || hashToken;

        initApp(token).then(data => {
            const { isError, token } = data;
            if (isError) {
                this.setState({
                    isShowLoadingTitle: false
                })
            } else if (token) {
                setCookie('auth', data.token);
                history.push('/');
            }
        })
    }


    render() {
        const { isShowLoadingTitle } = this.state;
        return (
            <div>
                {isShowLoadingTitle ? <Loader /> : ''}
            </div>

        )
    }
}

function mapStateToProps(state) {
    return {

    }
}

function mapDispatchToProps(dispatch) {
    return {
        initApp: (tokenId) => dispatch(initApp(tokenId)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AuthPage);