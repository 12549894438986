import { showNotification } from "../store/actions/notification";
import store from '../store/store'
import { signOut } from "../containers/App/actions";
import { authNotification } from "../components/Snackbar";
import { removeTextInBrackets } from "./utils";

export const typedErrorMsg = (code, msg) => code === 0 ? msg : `Code: ${code}. ${msg}`;

const codeDescription = {
    1: 'The operation was cancelled, typically by the caller.',
    2: 'Unknown error.',
    3: 'The client specified an invalid argument.',
    4: 'The deadline expired before the operation could complete.',
    5: 'Some requested entity was not found.',
    6: 'The entity that a client attempted to create already exists.',
    7: 'The caller does not have permission to execute the specified operation. ',
    8: 'Some resource has been exhausted, perhaps a per-user quota, or perhaps the entire file system is out of space.',
    9: 'The operation was rejected because the system is not in a state required for the operation\'s execution.',
    10: 'The operation was aborted, typically due to a concurrency issue such as a sequencer check failure or transaction abort.',
    11: 'The operation was attempted past the valid range. ',
    12: 'The operation is not implemented or is not supported/enabled in this service.',
    13: 'Internal errors.',
    14: 'The service is currently unavailable.',
    15: 'Unrecoverable data loss or corruption.',
    16: 'The request does not have valid authentication credentials for the operation.',
};

export const ERROR_TYPE_NOTIFICATION = 'error';

const showNotificationAction = (code, msg, typeNotification, timer, classNames, customNotification) => store.dispatch(showNotification(code, msg, typeNotification, timer, classNames, customNotification));
export const signOutAction = () => store.dispatch(signOut());

export const handleServiceError = (code, msg) => {
    const errorMessage = removeTextInBrackets(msg);
    if (code === 16) {
        signOutAction();
        window.location = '/';
    } else {
        const message = getErrorMsg(code, errorMessage);

        showNotificationAction(code, message, ERROR_TYPE_NOTIFICATION, null);
    }
};

export const onGoogleAuthError = (code, msg, resolve) => {
    showNotificationAction(code, '', ERROR_TYPE_NOTIFICATION, null, '', authNotification);
    resolve({ isError: true })
}

const getErrorMsg = (code, msg) => {
    let message = '';
    if (msg && msg !== '') {
        message = typedErrorMsg(code, msg);
    } else {
        message = typedErrorMsg(code, codeDescription[code]);
    }

    return message;
}